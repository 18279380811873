import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;

    img {
        width: 75px;
    }
    p {
        font-family: "M Medium";
        font-size: 15px;
        color: white;
        text-align: center;
    }

    @media only screen and (min-width: 1800px) {
        p {
            font-size: 20px;
            width: 295px;
        }
        img {
            width: 130px;
        }
    }

    @media only screen and (min-width: 2500px) {
        p {
            font-size: 29px;
            width: 415px;
            margin-bottom: .2%;
        }
        img {
            width: 170px;
        }
    }

    @media only screen and (min-width: 3500px) {
        p {
            font-size: 49px;
            width: 635px;
            
        }
        img {
            width: 170px;
        }
    }

    @media only screen and (max-width: 750px) {
        margin-bottom: 5%;
    }

    @media only screen and (max-width: 500px) {
        p {
            font-size: 4vw;
        }
        img {
            width: 18vw;
        }
    }
`   

const ServicioComponent = (props) => {

    return (
        <MainDiv>
            <img src={props.image} alt="imagen-servicio" />
            <p>{props.text}</p>
        </MainDiv>
    )
}

export default ServicioComponent
