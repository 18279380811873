import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"

const MainDiv = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    height: 100px;
    background-color: #0B2A3B;

    img {
        width: 40px;
        height: 40px;
        margin-right: 5%;
        margin-left: 5%;
    }
    p {
        font-family: "M Medium";
        font-size: 15px;
        color: white;
        text-align: left;
        margin-right: 5%;
    }

    @media only screen and (min-width: 1800px) {
        width: 520px;
        height: 150px;
        p {
            font-size: 25px;
            width: 340px;
        }
        img {
            width: 80px;
            height: 80px;
        }
    }
    @media only screen and (min-width: 2500px) {
        width: 630px;
        height: 190px;
        p {
            font-size: 30px;
            width: 780px;
        }
        img {
            width: 90px;
            height: 90px;
        }
    }
    @media only screen and (min-width: 3500px) {
        width: 830px;
        height: 190px;
        p {
            font-size: 35px;
            width: 880px;
        }
        img {
            width: 100px;
            height: 100px;
        }
    }
    @media only screen and (min-width: 4000px) {
        width: 860px;
        height: 220px;
        p {
            font-size: 40px;
            width: 980px;
        }
        img {
            width: 120px;
            height: 120px;
        }
    }
    @media only screen and (max-width: 1200px) {
        width: 300px;
    }
    @media only screen and (max-width: 910px) {
        width: 250px;
    }
    @media only screen and (max-width: 761px) {
        width: 80vw;
        margin-bottom: 5%;
    }

    @media only screen and (max-width: 500px) {
        p {
            font-size: 3.7vw;
        }
        img {
            width: 18vw;
            height: 18vw;
        }
    }
`   

const ServicioComponent = (props) => {

    return (
        <MainDiv>
            <img src={props.image} alt="imagen-inversion" />
            <p>{props.text}</p>
        </MainDiv>
    )
}

export default ServicioComponent
