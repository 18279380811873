import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "../../../css/typography.css"

const Button = styled.div`

    font-family: "H Bold";
    font-size: 17px;
    color: white;
    text-decoration: none;
    position:relative;
    color: white;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    

    a {
        text-decoration: none;
        color: white;
    }

    :before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: #808083;
        width: 100%;
        height: 100%;
        transition: .2s;
        -webkit-transform: skewX(-10deg);
        -moz-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);
    }

    @media only screen and (min-width: 1800px) {
        width: 150px;
        height: 50px;
        font-size: 20px;
        margin-top: 50px;
  }
  @media only screen and (min-width: 2500px) {
        width: 210px;
        height: 60px;
        font-size: 26px;
  }

  @media only screen and (min-width: 3500px) {
        width: 330px;
        height: 70px;
        font-size: 42px;
        margin-top: 150px;
  }

  @media only screen and (min-width: 4000px) {
        width: 430px;
        height: 90px;
        font-size: 52px;
        margin-top: 150px;
  }
`

const MainDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`

const ButtonComponent = (props) => {

    return (
        <MainDiv>
            <Button><Link to='/contacto'>{props.title}</Link></Button>
        </MainDiv>
    )
}

export default ButtonComponent
