import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import "../css/typography.css"
import useWindowSize from "../hooks/useWindowSize"
import Background from "../images/home/ImageBackground.webp"
import TitleBackground from "../images/home/TitleBackground.webp"
import SectionBackground from "../images/home/SectionBackground.webp"
import TitleBackMov from "../images/home/TitleBackMov.webp"
import CTA from "../components/buttons/cta"
import CTADIF from "../components/buttons/ctaDif"
import Dots from "../images/home/Dots.webp"
import Acompañar from "../images/home/servicios/Acompañar.webp"
import Asesoría from "../images/home/servicios/Asesoría.webp"
import Buscar from "../images/home/servicios/Buscar.webp"
import Coordinar from "../images/home/servicios/Coordinar.webp"
import Encontrar from "../images/home/servicios/Encontrar.webp"
import Negociar from "../images/home/servicios/Negociar.webp"
import Recomendar from "../images/home/servicios/Recomendar.webp"
import Servicio from "../components/home/servicio"
import FourthSectionPng from "../images/home/FourthSection.webp"
import Inversion from "../components/home/inversiones"
import Inmuebles from "../images/home/inversiones/Inmuebles.webp"
import Mini from "../images/home/inversiones/Mini.webp"
import Naves from "../images/home/inversiones/Naves.webp"
import Propiedades from "../images/home/inversiones/Propiedades.webp"
import ViviendasUno from "../images/home/inversiones/ViviendasUno.webp"
import Viviendas from "../images/home/inversiones/Viviendas.webp"
import Quienes from "../images/home/Quienes.webp"
import { Helmet } from 'react-helmet'

const FirstSection = styled.div`
  background-image: url(${Background});
  background-size: cover;
  margin: 0;
  color: white;
  padding: 5% 0 5% 0;

  >div:nth-child(1) {
    width: 900px;
    background-image: url(${TitleBackground});
    background-size: cover;
    font-size: 15.5px;
    font-family: 'M Extra Bold';
    padding: .1% 0 .1% 3%;
  }
  >div:nth-child(2) {
    width: 870px;
    background-image: url(${SectionBackground});
    background-size: cover;
    font-size: 17px;
    font-family: 'M Medium';
    padding: 2% 0 .1% 3%;
  }
  >div:nth-child(2) p {
    width: 800px;
  }

  @media only screen and (min-width: 1800px) {
      >div:nth-child(1) {
        width: 1400px;
        font-size: 25px;
      }
      >div:nth-child(2) {
        width: 1350px;
        font-size: 30px;
        padding: 2% 0 .1% 3%;
      }
      >div:nth-child(2) p {
        width: 1200px;
      }
    }

    @media only screen and (min-width: 3500px) {
      >div:nth-child(1) {
        width: 1900px;
        font-size: 35px;
      }
      >div:nth-child(2) {
        width: 1650px;
        font-size: 39px;
        padding: 2% 0 .1% 3%;
      }
      >div:nth-child(2) p {
        width: 1495px;
      }
    }
    @media only screen and (min-width: 4000px) {
      >div:nth-child(1) {
        width: 2200px;
        font-size: 40px;
      }
      >div:nth-child(2) {
        width: 2050px;
        font-size: 49px;
        padding: 2% 0 .1% 3%;
      }
      >div:nth-child(2) p {
        width: 1795px;
      }
    }

  @media only screen and (max-width: 885px) {
    >div:nth-child(1) {
      width: 750px;
      font-size: 14.5px;
      padding: .1% 0 .1% 3%;
    }
    >div:nth-child(2) {
      width: 740px;
      padding: 2% 0 .1% 3%;
    }
    >div:nth-child(2) p {
      width: 680px;
    }
  }
  @media only screen and (max-width: 736px) {
    >div:nth-child(1) {
      width: 580px;
      font-size: 11px;
      padding: .1% 0 .1% 3%;
    }
    >div:nth-child(2) {
      width: 560px;
      background-position-x: 100%;
    }
    >div:nth-child(2) p {
      width: 490px;
    }
  }
  @media only screen and (max-width: 570px) {
    width: 100vw;

    >div:nth-child(1) {
      width: 99%;
      font-size: 3vw;
      padding: .1% 0 .1% 3%;
      background-image: url(${TitleBackMov});
      background-position-x: 100%;
    }
    >div:nth-child(1) h1 {
      width: 92%;
      
    }
    >div:nth-child(2) {
      width: 100%;
      background-position-x: 100%;
      padding: 5% 9% 3% 2%;
    }
    >div:nth-child(2) p {
      width: 86%;
      line-height: 150%;
      font-size: 4vw;
    }
  }
`

const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    font-family: 'M Regular';
    font-size: 23px;
    width: 350px;
    color: #0B2A3B;
    text-align: center;
    margin-top: 13%;
  }

  span {
    font-family: 'M Extra Bold';
  }

  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4%;
  }
  >div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  ul li {
    font-family: "M Medium";
    font-size: 17px;
    line-height: 140%;
    margin-bottom: 4%;
    width: 40vw;
  }
  >div:nth-child(3) {
    margin-bottom: 5%;
    margin-top: 2%;
  }
  .dots-top {
    position: absolute;
    left: 1%;
    top: 5%;
    width: 90px;
  }
  .dots-bottom {
    position: absolute;
    right: 1%;
    bottom: 5%;
    width: 90px;
  }

  @media only screen and (min-width: 1200px) {
    >div:nth-child(1) h2 {
      font-size: 25px;
      width: 380px;
    }
    >div:nth-child(2) ul li {
      font-size: 19px;   
    }
  }

  @media only screen and (min-width: 1800px) {
    >div:nth-child(1) h2 {
      font-size: 40px;
      width: 646px;
    }
    >div:nth-child(2) ul li {
      font-size: 25px;   
    }
    .dots-top {
      width: 7vw;
      top: 3%;
      left: 2%;
    }
    .dots-bottom {
      width: 7vw;
      right: 2%;
    }
  }

  @media only screen and (min-width: 2500px) {
    >div:nth-child(1) h2 {
      font-size: 60px;
      width: 970px;
    }
    >div:nth-child(2) ul li {
      font-size: 35px;   
    }
  }

  @media only screen and (min-width: 3500px) {
    >div:nth-child(1) h2 {
      font-size: 68px;
      width: 1060px;
    }
    >div:nth-child(2) ul li {
      font-size: 45px;   
    }
  }

  @media only screen and (min-width: 4000px) {
    >div:nth-child(1) h2 {
      font-size: 82px;
      width: 1250px;
    }
    >div:nth-child(2) ul li {
      font-size: 49px;   
    }
  }

  @media only screen and (max-width: 930px) {
    .dots-top {
      width: 60px;
    }
    .dots-bottom {
      width: 60px;
    }
  }

  @media only screen and (max-width: 500px) {
    ul li {
    font-size: 4vw;
    line-height: 140%;
    margin-bottom: 4%;
    width: 90%;
  }
  .dots-top {
      width: 15vw;
      top: 3%;
      left: -10%;
    }
    .dots-bottom {
      width: 15vw;
      right: 2%;
    }
  }
`

const ThirdSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #0B2A3B;
  z-index: 0;

  h2 {
    margin-top: 13%;
    font-family: 'M Regular';
    font-size: 23px;
    width: 350px;
    color: white;
    text-align: center;
  }
  span {
    font-family: 'M Extra Bold';
  }
  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4%;
  }
  >div:nth-child(2) {
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    margin-bottom: 5%;
  }
  >div:nth-child(3) {
    display: flex;
    width: 65vw;
    justify-content: space-between;
  }
  >div:nth-child(4) {
    margin-bottom: 5%;
    margin-top: 2%;
  }
  .second-section-dots {
    position: absolute;
    left: 1%;
    top: 6.5%;
    width: 90px;
  }
  @media only screen and (min-width: 1800px) {
    >div:nth-child(1) h2 {
      font-size: 40px;
      width: 550px;
    }
    .second-section-dots {
      width: 7vw;
      top: 3%;
      left: 2%;
    }
    
   
  }
  @media only screen and (min-width: 2500px) {
    >div:nth-child(1) h2 {
      font-size: 60px;
      width: 750px;
    }
   
  }
  @media only screen and (min-width: 3500px) {
    >div:nth-child(1) h2 {
      font-size: 68px;
      width: 950px;
    }
   
  }
  @media only screen and (min-width: 4000px) {
    >div:nth-child(1) h2 {
      font-size: 82px;
      width: 970px;
    }
   
  }  
  @media only screen and (max-width: 750px) {
    >div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      margin-bottom: 5%;
    }
    >div:nth-child(3) {
      margin-bottom: 5%;
      margin-top: 2%;
    }
  }
`

const FourthSection = styled.div`
  background-image: url(${FourthSectionPng});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 400px;
  background-position: center;
`

const FifthSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    margin-top: 13%;
    font-family: 'M Regular';
    font-size: 23px;
    width: 350px;
    color: #0B2A3B;
    text-align: center;
  }
  span {
    font-family: 'M Extra Bold';
  }
  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4%;
  }
  >div:nth-child(2), >div:nth-child(3) {
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    margin-bottom: 5%;
  }
  .second-section-dots {
    position: absolute;
    left: 1%;
    top: 6.5%;
    width: 90px;
  }

  @media only screen and (min-width: 1800px) {
    >div:nth-child(1) h2 {
      font-size: 40px;
      width: 680px
    }
  }

  @media only screen and (min-width: 2500px) {
    >div:nth-child(1) h2 {
      font-size: 60px;
      width: 980px
    }
  }

  @media only screen and (min-width: 3500px) {
    >div:nth-child(1) h2 {
      font-size: 68px;
      width: 1200px
    }
  }
  @media only screen and (min-width: 4000px) {
    >div:nth-child(1) h2 {
      font-size: 82px;
      width: 1400px
    }
  }

  @media only screen and (max-width: 750px) {
    >div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      margin-bottom: 5%;
    }
  }
`

const SeparatorDark = styled.div`
  width: 50px;
  border-bottom: 3px solid #0B2A3B;
  border-radius: 50px;
  
  @media only screen and (min-width: 1200px) {
        
        width: 80px;  
    }
    @media only screen and (min-width: 1800px) {
        
        width: 120px;
    }
    @media only screen and (min-width: 2500px) {
        
        width: 200px;
    }
    @media only screen and (min-width: 3500px) {
        
        width: 250px;
    }
` 
const SeparatorLight = styled.div`
  width: 50px;
  border-bottom: 3px solid white;
  border-radius: 50px;

  @media only screen and (min-width: 1200px) {
        
        width: 80px;
    }
    @media only screen and (min-width: 1800px) {
        
        width: 120px;
    }
    @media only screen and (min-width: 2500px) {
        
        width: 200px;
    }
    @media only screen and (min-width: 3500px) {
        
        width: 250px;  
    }
  @media only screen and (max-width: 750px) {
    margin-bottom: 10%;
  }
` 

const LastSection = styled.div`
  display: flex;
  width: 100vw;
  justify-content: space-between;
  padding: 8% 5% 8% 5%;
  position: relative;
  z-index: 0;
  background-color: #F7F7F7;
  overflow-y: hidden;
  
  h2 {
    font-family: 'M Regular';
    font-size: 23px;
    color: #0B2A3B;
    text-align: center;
  }
  span {
    font-family: 'M Extra Bold';
  }
  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  p {
    font-family: 'M Regular';
    font-size: 17px;
    width: 45vw;
    margin-bottom: .5%;
  }
  img {
    width: 60%;
    margin-left: 10%;
  }
  .dots-top {
    position: absolute;
    left: -9%;
    top: 3%;
    width: 90px;
  }
  .dots-bottom {
    position: absolute;
    right: 17%;
    bottom: 8%;
    width: 90px;
    z-index: -5;
  }
  
  @media only screen and (min-width: 1800px) {
    h2 {
      font-size: 40px;
    }
    >div:nth-child(1) p {
      font-size: 30px;
      width: ;
    }
    img {
      width: 72%;
      margin-left:100px;
    }
    .dots-top {
      width: 110px;
      left: -2px
    }
    .dots-bottom {
      width: 110px;
      right: 14%;
    }
  }
    @media only screen and (min-width: 2500px) {
    
    h2 {
      font-size: 60px;
    }
    >div:nth-child(1) p {
      font-size: 40px;
      width: ;
    }
    img {
      width: 75%;
      margin-left:100px;
    }
    .dots-top {
      width: 130px;
      left: -2px
    }
    .dots-bottom {
      width: 150px;
      right: 11%;
    }
    }
    @media only screen and (min-width: 3500px) {
    
    h2 {
      font-size: 68px;
    }
    >div:nth-child(1) p {
      font-size: 60px;
      width: 1300px;
    }
    img {
      width: 80%;
      margin-right: 590px;
    }
    .dots-top {
      width: 170px;
      left: -2px
    }
    .dots-bottom {
      width: 200px;
      right: 1%;
    }
    }
    @media only screen and (min-width: 4000px) {
    
    h2 {
      font-size: 82px;
    }
    >div:nth-child(1) p {
      font-size: 70px;
      width: 1700px;
    }
    img {
      width: 80%;
      margin-right: 1290px;
    }
    .dots-top {
      width: 220px;
      left: -2px
    }
    .dots-bottom {
      width: 220px;
      right: 1%;
    }
  }

  @media only screen and (max-width: 1190px) {
    .dots-top {
    left: -9%;
    top: -3%;
    width: 90px;
    }
    .dots-bottom {
      right: 17%;
      bottom: 9%;
      width: 90px;
      z-index: -5;
    }
    img {
      width: 70%;
    }
  }
  @media only screen and (max-width: 860px) {
    .dots-top {
      left: -9%;
      top: -11%;
      width: 90px;
    }
    .dots-bottom {
      right: 17%;
      bottom: 15%;
      width: 90px;
      z-index: -5;
    }
    img {
      width: 75%;
    }
  }
  @media only screen and (max-width: 750px) {
    
  }
`
const LastSectionMov = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
  position: relative;
  z-index: 0;
  background-color: #F7F7F7;
  overflow: hidden;

  h2 {
    font-family: 'M Regular';
    font-size: 23px;
    color: #0B2A3B;
    text-align: center;
  }
  span {
    font-family: 'M Extra Bold';
  }
  p {
    font-family: 'M Regular';
    font-size: 17px;
    width: 90vw;
    margin-bottom: .5%;
  }
  img {
    width: 90vw;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .dots-top {
    position: absolute;
    left: -9%;
    top: -11%;
    width: 90px;
  }
  .dots-bottom {
    position: absolute;
    right: 10%;
    bottom: 1%;
    width: 90px;
    z-index: -5;
  }

  @media only screen and (max-width: 540px) {
    p {
      font-size: 4vw;
    }
    .dots-bottom {
    position: absolute;
    right: 1%;
    bottom: 1%;
    width: 90px;
    z-index: -5;
    }
  }
`

const IndexPage = () => {
  const windowSize = useWindowSize();
  const [hasMounted, setHasMounted] = useState(false) 

  useEffect(() => {
      setHasMounted(true)
  }, [])

  return hasMounted ? (
    <Layout>
      <Helmet>
        <title>Invierte en el extranjero | Bienes raíces en España</title>
        <meta name="description" content="Invierte en bienes inmuebles en España desde México. Te acompañamos en todas las etapas de invertir en el extranjero y hacemos el proceso más liviano."/>
      </Helmet>
      <FirstSection title="Invierte en bienes raíces en las mejores ciudades de España.">
        <div>
          <h1>Tu Personal Shopper Inmobiliario en España</h1>
        </div>
        <div>
          <p>Como Personal Shopper Inmobiliario, en INGEZAR representamos los intereses del inversionista en todo momento y nos abrimos a todas las posibilidades del mercado de cada ciudad, ya que no tenemos compromiso por vender una cartera de propiedades específica.</p>
          <p>Cada proyecto y búsqueda inmobiliaria es única, dado que surge de las necesidades específicas de cada cliente.</p>
          <p>Con trato personalizado y confidencial, coordinamos el proceso de principio a fin, ahorrando tiempo y dinero al inversionista.</p>
        </div>
      </FirstSection>
      <SecondSection>
        <div>
          <h2>¿POR QUÉ INVERTIR EN <span>BIENES RAÍCES EN ESPAÑA?</span></h2>
          <SeparatorDark />
        </div>
          {windowSize > 500 ? (
            <div>
              <ul>
                <li>Seguridad Jurídica de tus inversiones.</li>
                <li>Para invertir, no es requisito tener residencia o nacionalidad.</li>
                <li>Inversiones atractivas en inmuebles desde €200,000 euros. ​</li>
                <li>Retornos competitivos (CAP rates) en euros.</li>
                <li>Obtención de la Golden Visa de residente para el inversionista y sus familiares directos a partir de inversiones de €500,000 euros en bienes inmuebles.</li>
              </ul>
              <ul>
                <li>Entorno económico estable.</li>
                <li>Sector inmobiliario con gran dinamismo y crecimiento.</li>
                <li>Acceso a financiamiento hipotecario con tasas de interés muy atractivas.</li>
                <li>El impulso que darán al mercado inmobiliario en España los €70,000 millones de euros provenientes de los fondos Next Generation de la Unión Europea para financiar el Plan de Recuperación español.</li>
              </ul>
            </div>
          ) : (
            <div>
              <ul>
                <li>Seguridad Jurídica de tus inversiones.</li>
                <li>Para invertir, no es requisito tener residencia o nacionalidad.</li>
                <li>Inversiones atractivas en inmuebles desde €200,000 euros. ​</li>
                <li>Retornos competitivos (CAP rates) en euros.</li>
                <li>Obtención de la Golden Visa de residente para el inversionista y sus familiares directos a partir de inversiones de €500,000 euros en bienes inmuebles.</li>
                <li>Entorno económico estable.</li>
                <li>Sector inmobiliario con gran dinamismo y crecimiento.</li>
                <li>Acceso a financiamiento hipotecario con tasas de interés muy atractivas.</li>
                <li>El impulso que darán al mercado inmobiliario en España los €70,000 millones de euros provenientes de los fondos Next Generation de la Unión Europea para financiar el Plan de Recuperación español.</li>
              </ul>
            </div>
          )}
        <div>
          <CTA title='CONTÁCTANOS' />
        </div>
        <img src={Dots} alt="Dots" className="dots-top" />
        <img src={Dots} alt="Dots" className="dots-bottom" />
      </SecondSection>
      <ThirdSection>
        <div>
          <h2>NUESTROS <span>SERVICIOS</span></h2>
          <SeparatorLight />
        </div>
        {windowSize > 750 ? (
          <>
          <div>
            <Servicio image={Acompañar} text='Acompañar al inversionista en su proceso de compra en el extranjero.' />
            <Servicio image={Asesoría} text='Asesoría de expertos especializados en cada etapa del proceso.' />
            <Servicio image={Buscar} text='Buscar y preseleccionar propiedades acordes con el objetivo de inversión.' />
            <Servicio image={Encontrar} text='Encontrar la opción óptima en cuanto a rendimiento, plusvalía, entorno y servicios.' />
          </div>
          <div>
            <Servicio image={Negociar} text='Negociar los mejores términos posibles para el cierre de la compra-venta.' />
            <Servicio image={Coordinar} text='Coordinar los servicios jurídicos: Migratorios, Inmobiliarios, Fiscales, Societarios.' />
            <Servicio image={Recomendar} text='Recomendar servicios bancarios, hipotecarios y de seguros, así como agencias especializadas para el alquiler y administración de las propiedades.' />
          </div>
          </>
        ) : (
          <div>
            <Servicio image={Acompañar} text='Acompañar al inversionista en su proceso de compra en el extranjero.' />
            <Servicio image={Asesoría} text='Asesoría de expertos especializados en cada etapa del proceso.' />
            <Servicio image={Buscar} text='Buscar y preseleccionar propiedades acordes con el objetivo de inversión.' />
            <Servicio image={Encontrar} text='Encontrar la opción óptima en cuanto a rendimiento, plusvalía, entorno y servicios.' />
            <Servicio image={Negociar} text='Negociar los mejores términos posibles para el cierre de la compra-venta.' />
            <Servicio image={Coordinar} text='Coordinar los servicios jurídicos: Migratorios, Inmobiliarios, Fiscales, Societarios.' />
            <Servicio image={Recomendar} text='Recomendar servicios bancarios, hipotecarios y de seguros, así como agencias especializadas para el alquiler y administración de las propiedades.' />
          </div>
        )}
        
        <div>
          <CTA title='AGENDA UNA CITA' />
        </div>
        <img src={Dots} alt="Dots" className="second-section-dots" />
      </ThirdSection>
      <FourthSection title="Basílica de la Sagrada Familia"/>
      <FifthSection>
        <div>
          <h2>TIPOS DE INVERSIONES <span>INMOBILIARIAS EN ESPAÑA</span></h2>
          <SeparatorDark />
        </div>
        {windowSize > 750 ? (
          <>
          <div>
            <Inversion image={ViviendasUno} text='Viviendas para residir o para renta, temporal o de larga estancia.' />
            <Inversion image={Viviendas} text='Viviendas para renta en co-living (estudiantes, profesionistas independientes).' />
            <Inversion image={Propiedades} text='Propiedades para reformar y revender.' />
          </div>
          <div>
            <Inversion image={Inmuebles} text='Inmuebles comerciales y de servicios.' />
            <Inversion image={Mini} text='Mini bodegas (Trasteros).' />
            <Inversion image={Naves} text='Naves para la industria logística.' />
          </div>
          </>
        ) : (
          <div>
            <Inversion image={ViviendasUno} text='Viviendas para residir o para renta, temporal o de larga estancia.' />
            <Inversion image={Viviendas} text='Viviendas para renta en co-living (estudiantes, profesionistas independientes).' />
            <Inversion image={Propiedades} text='Propiedades para reformar y revender.' />
            <Inversion image={Inmuebles} text='Inmuebles comerciales y de servicios.' />
            <Inversion image={Mini} text='Mini bodegas (Trasteros).' />
            <Inversion image={Naves} text='Naves para la industria logística.' />
          </div>
        )}
      </FifthSection>
      {windowSize > 750 ? (
        <LastSection>
          <div>
            <h2>¿QUIÉNES <span>SOMOS?</span></h2>
            <SeparatorDark />
            <p>En Ingezar España somos tu Personal Shopper Inmobiliario.</p>
            <p>En Ingezar México nos especializamos en el trato personalizado a inversionistas a nivel nacional, ofreciendo oportunidades de inversión y expansión para las principales marcas del país, desarrolladores de vivienda, comerciales e industriales.</p>
            <CTADIF title='CONTÁCTANOS' />
          </div>
          <div>
            <img src={Quienes} alt="Cuatro torres business area" />
          </div>
          <img src={Dots} alt="Dots" className="dots-top" />
          <img src={Dots} alt="Dots" className="dots-bottom" />
        </LastSection>
      ) : (
        <LastSectionMov>
          <h2>¿QUIÉNES <span>SOMOS?</span></h2>
          <SeparatorDark />
          <p>En Ingezar España somos tu Personal Shopper Inmobiliario.</p>
          <p>En Ingezar México nos especializamos en el trato personalizado a inversionistas a nivel nacional, ofreciendo oportunidades de inversión y expansión para las principales marcas del país, desarrolladores de vivienda, comerciales e industriales.</p>
          <img src={Quienes} alt="Cuatro torres business area" />
          <CTA title='CONTÁCTANOS' />
          <img src={Dots} alt="Dots" className="dots-top" />
          <img src={Dots} alt="Dots" className="dots-bottom" />
        </LastSectionMov>
      )}
      
    </Layout>
  ) : (
    null
  )
}

export default IndexPage
